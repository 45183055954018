import React, { useState } from "react"
import "./assets/styles/_index.scss"
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
import AarraAboutSidebar from "./AarraAboutSidebar"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import ProptvStand from "../../proptvstand/ProptvStand"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Button } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import VideoPlayer from "../../videoplayer/VideoPlayer"
import Slider from "react-slick"

const videoUrl = "https://www.propverse.ai/utility/assets/aarra-developer/Aarra-video.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const videoUrlStand = "https://www.propverse.ai/utility/assets/aarra-developer/Aarra-video.mp4"
const thumbnailUrlStand = require("/utility/assets/homepage/prop-tv.png")

const propGpt = require("/utility/assets/common/propGPT.png")
const project = require("/utility/assets/icons/project-list.png")
const about = require("/utility/assets/icons/about-developer.png")
const enquiry = require("/utility/assets/icons/enquiry.png")

function AarraAbout() {
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);

    const toggleRightSidebar = () => {
        setIsRightSidebarOpen(isRightSidebarOpen);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,
    
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: false,
              centerMode: true,
              centerPadding: "0px",
              slidesToShow: 1,
            },
          },
    
        ],
    
      }

    return (
        <>

            <div className="ProjectAboutPageSectionAarra">
                <div className="d-none d-md-block">
                    <AarraAboutSidebar
                        isOpen={isRightSidebarOpen}
                        toggleRightSidebar={toggleRightSidebar}
                    />
                </div >

                <ProjectCtaButton />

                <div className="proptv-stand-wrapper d-none d-md-block">
                    <ProptvStand
                        videoUrlStand={videoUrlStand}
                        thumbnailUrlStand={thumbnailUrlStand}
                    />
                </div>

                <Container fluid className="ProjectAboutDetailFooterSection">
                    <Row>
                        <Col lg={4} className="p-0 textAlignCenter">
                        </Col>
                        <Col lg={4} className="p-0 textAlignCenter">
                            <Row className="ProjectAboutDetailListBg d-none d-md-block">
                                <Col lg={6} className="p-0 pr-3 textAlignCenter">
                                    <div className="ProjectAboutDetailList">
                                        <Link to="/developers/aarra-developer/">
                                            <p><img src={project} fluid className="" /> Project List</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={6} className="p-0 textAlignCenter">
                                    <div className="AboutDev">
                                        <Link to="/developers/aarra-developer/about/">
                                            <p><img src={about} fluid className="" /> About Developer</p>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="p-0 textAlignRight">
                            <img src={propGpt} fluid className="BoothLogoSection" />
                        </Col>
                    </Row>
                </Container>

                <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectDetailsList">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Aarra Developers
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="aboutus"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="aboutus" title="About Us">
                                        <div className="ReadMoreSection">
                                            <h4 className='m-0'>Aarra Developers</h4>
                                            <p className="mb-0">
                                                <p className="AboutExpoPara">Aarra Group is one of the subcontinent’s largest and oldest realty conglomerates with a pioneering heritage spanning seven decades and footprints across seven countries.</p>
                                            </p>

                                            {isShowMore && (
                                                <p className="mb-0">
                                                    <p className="AboutExpoPara"> With expertise across construction, development, engineering, funding, and consultancy, the fourth-generation company offers full-service real estate solutions, with the backing of India’s largest labor force. Above all, we are committed to building a better, more sustainable world for future generations. That’s what we’ve been doing since the 1950s, and it’s why we’re still here today.</p>

                                                    <p className="AboutExpoPara">As one of the only zero-debt real estate companies in India, it is our privilege to build on the wealth that the markets have created and share its benefits more equitably. Which is why, we have created a special 1,500 Cr. real estate fund along with Nisus, for special situations, and last-mile commercial and residential projects. We also extend our end-to-end expertise to projects, ranging from design, liaising & approvals, value engineering, PMC, construction management, legal, marketing & much more.</p>
                                                </p>
                                            )}
                                            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>

                                            <div className="AboutHallSection">
                                                <Button>Click here to download brochure</Button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="video" title="Video">
                                        <section className="pt-0">
                                            <Container className="p-0">
                                                <Slider {...settings} className="">
                                                    <div className="ProjectListAccordionSlider">
                                                        <VideoPlayer
                                                            videoUrl={videoUrl}
                                                            thumbnailUrl={thumbnailUrl}
                                                        />
                                                    </div>
                                                </Slider>
                                            </Container>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div >
        </>
    )
}

export default AarraAbout