import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function AarraReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Aarra Developers</h4>
            <p className="mb-0">
                <p className="AboutExpoPara">Aarra Group is one of the subcontinent’s largest and oldest realty conglomerates with a pioneering heritage spanning seven decades and footprints across seven countries. With expertise across construction, development, engineering, funding, and consultancy, the fourth-generation company offers full-service real estate solutions, with the backing of India’s largest labor force. Above all, we are committed to building a better, more sustainable world for future generations. That’s what we’ve been doing since the 1950s, and it’s why we’re still here today.</p>

            </p>

            {isShowMore && (
                <p className="mb-0">
                    <p className="AboutExpoPara">As one of the only zero-debt real estate companies in India, it is our privilege to build on the wealth that the markets have created and share its benefits more equitably. Which is why, we have created a special 1,500 Cr. real estate fund along with Nisus, for special situations, and last-mile commercial and residential projects. We also extend our end-to-end expertise to projects, ranging from design, liaising & approvals, value engineering, PMC, construction management, legal, marketing & much more.</p>
                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default AarraReadMore;