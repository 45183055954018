import React from 'react';
import "./assets/styles/_index.scss"
import { ArrowLeft } from 'react-bootstrap-icons'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from "gatsby"

import prevArrow from '/utility/assets/icons/prevArrow.png'
import nextArrow from '/utility/assets/icons/nextArrow.png'

import AarraDeveloperLogo from "/utility/assets/aarra-developer/aarra-developer-logo.png";
import AarraReadMore from './AarraReadMore';
import VideoPlayer from '../../videoplayer/VideoPlayer';

const videoUrl = "https://www.propverse.ai/utility/assets/aarra-developer/Aarra-video.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${prevArrow})` }}
      onClick={onClick}
    />
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${nextArrow})` }}
      onClick={onClick}
    />
  );
}


const AarraAboutSidebar = ({ isOpen, toggleRightSidebar }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },

    ],

  }


  return (
    <div className={`right-sidebar-about ${isOpen ? 'open' : ''}`}>

      <div className="aboutBackButton" onClick={toggleRightSidebar}><span>
        <Link to="/developers/aarra-developer/"><ArrowLeft /> Back</Link></span>
      </div>

      <div className='AboutTitleImage'>
        <img
          src={AarraDeveloperLogo}
          alt="2"

        />

      </div>

      <div className="AboutExpo">
        
        <Container>
          <Row>
            <AarraReadMore />
          </Row>
        </Container>
      </div>

      <div className="AboutRatingSection">
        <VideoPlayer
          videoUrl={videoUrl}
          thumbnailUrl={thumbnailUrl}
        />
      </div>


      <div className="AboutHallSection">
        <Button>Download Brochure</Button>
      </div>

    </div>
  );
};

export default AarraAboutSidebar;
